<template>
  <header class="container">
    <div class="row">
      <div class="col-6">
        <img src="/img/wtf_logo.svg" style="height: 7rem"  alt="Logo der WTF Kooperative eG"/>
        <h1>Beitritts-/ Beteiligungserklärung zur WTF Kooperative eG</h1>
        WTF Kooperative eG, Hinterhaus 3. OG, Forsmannstr. 14 b, 22303 Hamburg<br/>
        <span class="fw-bold">Registergericht:</span> Hamburg GnR 1113<br/>
        <span class="fw-bold">Vorstand:</span> Frank Landgraf, Frank Lanitz<br/>
        <span class="fw-bold">Aufsichtsratsvorsitz:</span> Simon Liebing
        <p>
          <a href="https://dejure.org/gesetze/GenG">(§§ 15, 15a und 15b GenG)</a
          >
          <br class="no-print"/>
          Version {{ version }}
        </p>

      </div>
      <div class="col-6">
        <qrcode-vue
          class="main-qr-code"
          :value="contentQrCode"
          :size="350"
          level="L"
          renderAs="svg"
          margin="10"
          style="margin: 0 auto; display: block"
        />
        <!-- {{contentQrCode}} -->
      </div>
    </div>

    <p class="no-print alert alert-danger">
      Derzeit nehmen wir keine neuen Mitglieder auf.
    </p>
    <p class="no-print">
      Bitte fülle alle Felder aus und klicke dann auf "Drucken". Das gedruckte
      Dokument muss dann noch mit Ort, Datum und Unterschrift versehen werden.
      Danach kannst Du die Unterlagen per Post ins Büro der WTF Kooperative eG versenden.
      Bei Fragen gibt es unten ein FAQ. Am besten aktivierst Du beim Drucken die Hintergrundgrafiken.
      <br/>
      Alle mit * markierten Felder sind Pflichtfelder.
    </p>
  </header>

  <main class="container">
    <form @submit.prevent="onSubmit">
      <section>
        <h2>Beitrittserklärung und Anteilszeichnung</h2>
        <div class="form-check mb-3">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            v-model="confirm_membership"
            id="confirm_membership"
            required
          />
          <label class="form-check-label beantraungs-text" for="confirm_membership">
            Ich beantrage hiermit die Aufnahme in die WTF Kooperative eG,
            bestätige die mir zur Verfügung gestellte <a href="https://wtf-eg.de/dokumente/wtf-satzung.pdf">Satzung</a>
            und <a href="https://wtf-eg.de/dokumente/wtf-ago.pdf">Allgemeine Geschäftsordnung (AGO)</a>
            und verpflichte mich zur Leistung nach Gesetz und Satzung vorgesehenen Zahlungen in
            Höhe von 100 € je Geschäftsanteil, einer einmaligen Aufnahmegebühr
            von 100 €, sowie einem jährlichen Beitrag in Höhe von 100 € (für 2024 ausnahmsweise 200 €), selbst
            per Überweisung mit dem Verwendungszweck Mitgliedsnummer (wenn
            vorhanden) bzw. <code>Name - Vorname - Geburtsdatum</code> an folgende
            Bankverbindung:
          </label>
          <table class="table table-striped mt-2">
            <tr class="no-print">
              <td>Kontoinhaberin:</td>
              <td>WTF Kooperative eG</td>
            </tr>
            <tr>
              <td>IBAN:</td>
              <td><strong>DE67 4476 1534 </strong>2301 4210 00</td>
            </tr>
            <tr class="no-print">
              <td>Bank:</td>
              <td>Volksbank in Südwestfalen eG</td>
            </tr>
            <tr class="no-print">
              <td>BIC:</td>
              <td> GENO DE M1 NRD</td>
            </tr>
          </table>
          <input
            class="form-check-input"
            type="checkbox"
            v-model="direct_debit"
            id="direct_debit"
          />
          <label for="direct_debit" class="form-check-label beantraungs-text">
            Bei erfolgreicher Aufnahme möchte ich künftig meinen Jahresbeitrag per Lastschrift begleichen.
          </label>
        </div>
        <div class="row">
          <div class="col-6 col-xs-12">
            <div class="mb-3">
              <label for="membership_type" class="form-label">Ich bin: </label>
              <select
                id="membership_type"
                class="form-select"
                aria-label="Mitgliedsart. Standard neues Mitglied"
                v-model="membership_type"
                required
              >
                <option
                  v-for="(item, index) in membership_type_options"
                  :key="index"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6 col-xs-12">
            <div class="mb-3">
              <label for="personhood_status" class="form-label"
                >Personen-Status</label
              >
              <select
                class="form-select"
                aria-label="todo"
                v-model="personhood_status"
                id="personhood_status"
              >
                <option
                  v-for="(item, index) in personhood_status_options"
                  :key="index"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6 col-xs-12">
            <div class="mb-3">
              <label for="shares" class="form-label"
                >Anzahl neu gezeichnete Anteile:*</label
              >
              <input
                type="text"
                class="form-control"
                id="shares"
                placeholder="21"
                v-model="shares"
                required
              />
            </div>
          </div>
          <div
            class="col-6 col-xs-12"
            v-if="membership_type !== membership_type_options[0]"
          >
            <div class="mb-3">
              Gesamtanzahl Anteile:
              <label for="shares_total" class="form-label"
                >Daraus folgende Gesamtanzahl:</label
              >
              <input
                type="text"
                class="form-control"
                id="shares_total"
                placeholder="42"
                v-model="shares_total"
                required
              />
            </div>
          </div>
        </div>

        <h3 class="mt-3">
          Angaben zu deiner
          <span v-if="personhood_status === personhood_status_options[0]"
            >Person</span
          >
          <span v-if="personhood_status === personhood_status_options[1]"
            >Firma</span
          >
        </h3>
        <div class="row">
          <div class="col-4 col-xs-12">
            <label for="nickname" class="form-label">Nickname:</label>
            <input
              type="text"
              class="form-control"
              id="nickname"
              v-model="nickname"
              placeholder=""
            />
          </div>
          <div class="col-4 col-xs-12">
            <label for="pronouns" class="form-label">Pronomen:</label>
            <input
              type="text"
              class="form-control"
              id="pronouns"
              v-model="pronouns"
              placeholder=""
            />
          </div>
          <div
            class="col-4 col-xs-12"
            v-if="membership_type !== membership_type_options[0]"
          >
            <label for="membership_number" class="form-label"
              >Deine Mitgliedsnummer: (sofern vorhanden)</label
            >
            <input
              type="text"
              class="form-control"
              id="membership_number"
              v-model="membership_number"
              placeholder=""
            />
          </div>
        </div>
        <p>
          Folgende Angaben bitte genau so, wie sie in Deinem Personalausweis
          stehen:
        </p>
        <div class="row">
          <div class="col-6 col-xs-12">
            <label for="first_name" class="form-label">Vorname:*</label>
            <input
              type="text"
              class="form-control"
              id="first_name"
              v-model="first_name"
              placeholder=""
              required
            />
          </div>
          <div class="col-6 col-xs-12">
            <label for="last_name" class="form-label">Nachname:*</label>
            <input
              type="text"
              class="form-control"
              id="last_name"
              v-model="last_name"
              placeholder=""
              required
            />
          </div>
        </div>
        <div class="row" v-if="personhood_status === personhood_status_options[0]">
          <div class="col-6 col-xs-12">
            <label for="date_of_birth" class="form-label">Geburtsdatum:*</label>
            <input
              type="text"
              class="form-control"
              id="date_of_birth"
              v-model="date_of_birth"
              placeholder=""
              required
            />
          </div>
          <div class="col-6 col-xs-12">
            <label for="place_of_birth" class="form-label">Geburtsort:*</label>
            <input
              type="text"
              class="form-control"
              id="place_of_birth"
              v-model="place_of_birth"
              placeholder=""
              required
            />
          </div>
        </div>
        <div
          class="row"
          v-if="personhood_status === personhood_status_options[1]"
        >
          <div class="col-6 col-xs-12">
            <label for="company_name" class="form-label">Firmenname:*</label>
            <input
              type="text"
              class="form-control"
              id="company_name"
              v-model="company_name"
              placeholder=""
              required
            />
          </div>
          <div class="col-3 col-xs-12">
            <label for="company_place" class="form-label">Sitz:*</label>
            <input
              type="text"
              class="form-control"
              id="company_place"
              v-model="company_place"
              placeholder=""
              required
            />
          </div>
          <div class="col-3 col-xs-12">
            <label for="registration_data" class="form-label"
              >Registerangaben:*</label
            >
            <input
              type="text"
              class="form-control"
              id="registration_data"
              v-model="registration_data"
              placeholder=""
              required
            />
          </div>
        </div>
        <h3 class="mt-3">Deine Kontaktdaten</h3>
        <div class="row">
          <div class="col-8 col-xs-12">
            <label for="address_1_name" class="form-label"
              >Anschriftzeile 1 (Name/Firma)*:</label
            >
            <input
              type="text"
              class="form-control"
              id="address_1_name"
              v-model="address_1_name"
              placeholder=""
              required
            />
          </div>
          <div class="col-4 col-xs-12">
            <label for="address_2_addtional" class="form-label"
              >Anschriftzeile 2 (Gebäude/Wohnung):</label
            >
            <input
              type="text"
              class="form-control"
              id="address_2_addtional"
              v-model="address_2_addtional"
              placeholder=""
            />
          </div>
        </div>
        <div class="row">
          <div class="col-8 col-xs-12">
            <label for="address_4_street" class="form-label"
              >Anschriftzeile 3 (Straße)*:</label
            >
            <input
              type="text"
              class="form-control"
              id="address_4_street"
              v-model="address_4_street"
              placeholder=""
              required
            />
          </div>
          <div class="col-4 col-xs-12">
            <label for="house_number" class="form-label">Hausnummer:*</label>
            <input
              type="text"
              class="form-control"
              id="house_number"
              v-model="house_number"
              placeholder=""
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-4 col-xs-12">
            <label for="city_code" class="form-label">Postleitzahl:*</label>
            <input
              type="text"
              class="form-control"
              id="city_code"
              v-model="city_code"
              placeholder=""
              required
            />
          </div>
          <div class="col-4 col-xs-12">
            <label for="city" class="form-label">Ort:*</label>
            <input
              type="text"
              class="form-control"
              id="city"
              v-model="city"
              placeholder=""
              required
            />
          </div>
          <div class="col-4 col-xs-12">
            <label for="country" class="form-label">Land:*</label>
            <select
              id="country"
              class="form-select"
              aria-label="Mitgliedsart. Standard neues Mitglied"
              v-model="country"
              required
            >
              <option
                v-for="(item, index) in iso3166"
                :key="index"
                :value="item.alpha3"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-xs-12">
            <label for="email" class="form-label">E-Mail:*</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="email"
              placeholder="beate@beispiel.de"
              required
            />
          </div>
          <div class="col-6 col-xs-12">
            <label for="phone_number" class="form-label">Telefonnummer:</label>
            <input
              type="tel"
              class="form-control"
              id="phone_number"
              v-model="phone_number"
              placeholder="+49 1515 123456"
            />
          </div>
        </div>
        <div>
          <label for="pgp" class="form-label">PGP-Fingerprint:*</label>
          <input
            type="text"
            class="form-control"
            id="pgp"
            v-model="pgp"
            placeholder=""
            required
          />
        </div>
        <p class="no-print">
          Die Nutzung von PGP-Verschlüsselung ist zwingende Voraussetzung für
          deine Interaktion mit der Genossenschaft. Bitte lade Deinen Public-Key
          entweder auf übliche Keyserver hoch oder sende ihn uns von genau der
          angegebenen Mailadresse als Mail-Anhang:
          <a href="mailto:member@wtf-eg.de">member@wtf-eg.de</a>. (als Betreff
          <code>{{ pubkeyEmailSubject }}</code> angegeben).
        </p>
        <p class="no-print">
          Wenn Du noch kein PGP-Key-Paar hast, musst Du Dir eines anlegen. Im
          Zweifelsfall helfen wir Dir dabei. Du kannst unter
          <a href='mailto:helpdesk@wtf-eg.de'>helpdesk@wtf-eg.de</a>
          Hilfe bekommen. Nützliche Tipps dazu findest Du auch auf
          <a href='https://howtopgp.jugendhackt.de/'>howtopgp.jugendhackt.de</a>.
          Es gibt übrigens auch Anbieter, die
          Mailverschlüsselung per Webmail anbieten, z. B.
          <a href="https://posteo.de/de">posteo.de</a> oder
          <a href="https://mailbox.org/de/">mailbox.org</a>. Es ist gar nicht so
          schwer! :)
        </p>
        <div class="job_chaos">
          <article class="job_situation">
            <h3>Berufliche Situation & Finanzen</h3>
            <label>Ich bin zur Zeit:</label>
            <ul class="list-group">
              <li class="list-group-item"
                :class="{ 'no-print': !status_1 }">
                <input
                  class="form-check-input me-1"
                  v-model="status_1"
                  type="checkbox"
                />
                nicht erwerbstätig <span class="no-print">(Student:in, Rentner:in, Sozialleistungsempfänger:in o.ä.)</span>
              </li>
              <li class="list-group-item"
                :class="{ 'no-print': !status_2 }">
                <input
                  class="form-check-input me-1"
                  v-model="status_2"
                  type="checkbox"
                />
                Angestellt
              </li>
              <li class="list-group-item"
                :class="{ 'no-print': !status_3}">
                <input
                  class="form-check-input me-1"
                  v-model="status_3"
                  type="checkbox"
                />
                Selbständig, und zwar:
              </li>
              <li
                class="list-group-item secondary-item"
                v-if="status_3"
                :class="{ 'no-print': !status_4 }"
              >
                <input
                  class="form-check-input me-1"
                  v-model="status_4"
                  type="checkbox"
                />
                Freiberufler:in
              </li>
              <li
                class="list-group-item secondary-item"
                v-if="status_3"
                :class="{ 'no-print': !status_5 }"
              >
                <input
                  class="form-check-input me-1"
                  v-model="status_5"
                  type="checkbox"
                />
                Kleinunternehmer:in im nach § 19 UStG
              </li>
              <li
                class="list-group-item secondary-item"
                v-if="status_3"
                :class="{ 'no-print': !status_6 }"
              >
                <input
                  class="form-check-input me-1"
                  v-model="status_6"
                  type="checkbox"
                />
                (Mit)Inhaber:in einer Firma
              </li>
              <li
                class="list-group-item secondary-item"
                v-if="status_3"
                :class="{ 'no-print': !status_7 }"
              >
                <input
                  class="form-check-input me-1"
                  v-model="status_7"
                  type="checkbox"
                  aria-label="Wenn Selbständig und nicht Freiberuflicher, Kleinunternehmer oder Mitinhaber"
                />
                Sonstiges
              </li>
            </ul>
          </article>
          <article class="chaos_connection">
            <h3 class="mt-3">Chaos-Connection</h3>
            <p class="no-print">
              Diese Angaben sind freiwillig, helfen uns aber dabei, Deinen
              Mitgliedsantrag zeitnah zu bearbeiten.
            </p>
            <div>
              <label for="chaos_connection" class="form-label"
                >Meine Verbindung zum Chaos:
              </label>
              <textarea
                class="form-control"
                id="chaos_connection"
                v-model="chaos_connection"
                ></textarea>
            </div>
            <p class="no-print">
              (Wo liegt Dein Hackspace/Erfa/Chaostreff, unter welchen Nicks
              kennt man Dich dort? Mit wem von dort dürfen wir Kontakt
              aufnehmen?)
            </p>
            <div>
              <label for="public_nick" class="form-label"
                >Meine Aktivitäten im Netz:
              </label>
              <input
                type="text"
                class="form-control"
                id="public_nick"
                v-model="public_nick"
                placeholder=""
              />
            </div>
            <p class="no-print">
              (Welche coolen Sachen machst Du in diesem Internet? Wo hat man
              Dich oder Deine Projekte schon mal gesehen? (Videos, Podcast,
              Vorträge, Blog, github, …)
            </p>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                v-model="status_public_projects"
                id="status_public_projects"
              />
              <label class="form-check-label" for="status_public_projects">
                Auf mich trifft das alles nicht so zu<span class="no-print">, aber ich kenne Leute aus
                dem Chaos / aus der WTF Kooperative eG, die meinen, die Genossenschaft wäre
                was für mich. (ggf. Name / Nick / Email angeben)</span>
              </label>
            </div>
          </article>
        </div>
        <hr class="no-print" />
        <p>
          Mit meiner Unterschrift bestätige ich, dass über mein Vermögen keine
          laufenden Insolvenzverfahren bestehen bzw. beantragt wurden.
        </p>
        <div class="row">
          <div class="col-4">
            Ort:
            <br />
            <br />
            <hr />
          </div>
          <div class="col-4">
            Datum:
            <br />
            <br />
            <hr />
          </div>
          <div class="col-4">
            Unterschrift:
            <br />
            <br />
            <hr />
          </div>
        </div>
        <div>
          <h3 class="mt-3 zulassung">
            Zulassung durch die Genossenschaft: (vom Vorstand auszufüllen)
          </h3>
          <div class="row">
            <div class="col">
              Ort, Datum / Stempel:
              <br />
              <br />
              <hr />
            </div>
            <div class="col">
              Unterschrift des Vorstands:
              <br />
              <br />
              <hr />
            </div>
          </div>
          <br />
        </div>
        <div class="mt-1">
          Impressums- und Postanschrift: WTF Kooperative eG, Hinterhaus 3. OG, Forsmannstr. 14 b,
          22303 Hamburg
        </div>
      </section>
      <section class="no-print mt-4">
        <button class="btn btn-wtf" type="submit">Jetzt drucken</button>
        <p class="mt-4">
          Wenn möglich, dann verändere bitte keine Daten mehr auf dem
          ausgedruckten Dokument. Außer Unterschrift, Datum und Ort ;)
        </p>
      </section>
    </form>
  </main>
  <faq></faq>
  <footer class="container no-print">
    <a href="https://wtf-eg.de/impressum/" class="m-4">Impressum</a>
    <a href="https://wtf-eg.de/datenschutz/" class="m-4">Datenschutz</a>
    <a href="https://git.wtf-eg.de/wtf-eg/digitale-online-offline-registration/" class="m-4">git(ea)</a>
  </footer>
</template>
<script>
/* eslint-disable */
// NPM Component
import QrcodeVue from 'qrcode.vue'

// JSON
import iso3166Import from '@/assets/iso3166.json'

// Internal Component
import Faq from '@/components/TheFaq.vue'

export default {
  name: "App",
  components: {
    QrcodeVue,
    Faq,
  },
  data() {
    return {
      version: "2024-05-29",
      confirm_membership: false,
      direct_debit: false,
      membership_type_options: [
        "Neues Mitglied",
        "Bestehendes Mitglied",
        "Übernehmendes Mitglied",
      ],
      membership_type: "Neues Mitglied", // See membership_type_option
      shares: 0, // Integer
      shares_total: 0, // Integer
      nickname: "",
      pronouns: "",
      membership_number: "",
      personhood_status: "Natürliche Person",
      personhood_status_options: ["Natürliche Person", "Juristische Person"],
      first_name: "",
      last_name: "",
      date_of_birth: "",
      place_of_birth: "",
      company_name: "",
      company_place: "",
      registration_data: "",
      address_1_name: "",
      address_2_addtional: "",
      address_4_street: "",
      house_number: "",
      city: "",
      city_code: "",
      country: "deu", // String; default: DEU,
      phone_number: "",
      email: "",
      pgp: "",
      chaos_connection: "",
      public_nick: "",
      status_public_projects: "",
      status_1: false,
      status_2: false,
      status_3: false,
      status_4: false,
      status_5: false,
      status_6: false,
      status_7: false,
      iso3166: iso3166Import
    };
  },
  computed: {
    contentQrCode: {
      get() {
        let membership = {
          email: this.email,
          membership_type: this.membership_type,
          shares: this.shares,
          shares_total: this.shares_total,
          nickname: this.nickname,
          pronouns: this.pronouns,
          personhood_status: this.personhood_status,
          first_name: this.first_name,
          last_name: this.last_name,
          date_of_birth: this.date_of_birth,
          place_of_birth: this.place_of_birth,
          address_1_name: this.address_1_name,
          address_2_addtional: this.address_2_addtional,
          address_4_street: this.address_4_street,
          house_number: this.house_number,
          city: this.city,
          city_code: this.city_code,
          country: this.country,
          phone_number: this.phone_number,
          pgp: this.pgp,
          chaos_connection: this.chaos_connection.substring(0,200),
          public_nick: this.public_nick.substring(0,100),
          direct_debit: this.direct_debit,
        };

        if (this.status_1) membership.nicht_erwerbstaetig = true;
        if (this.status_2) membership.arbeitnehmer = true;
        if (this.status_3) membership.selbststaendig = true;
        if (this.status_4) membership.freiberufler = true;
        if (this.status_5) membership.kleinunternehmer = true;
        if (this.status_6) membership.gesellschafter = true;
        if (this.status_7) membership.sonstiges = true;

        if (this.personhood_status === this.personhood_status_options[1]) {
          // Wenn Juristische Person
          membership = {
            ...membership,
            company_name: this.company_name,
            company_place: this.company_place,
            registration_data: this.registration_data,
          };
        }

        Object.keys(membership).map((item, index)=>{
          console.log(index, item)
          membership[index] = membership[item];
          delete membership[item]
        })

        return JSON.stringify(membership);
      },
    },
    pubkeyEmailSubject() {
      return `Aufnahmeantrag ${this.nickname ? this.nickname : '[dein Nickname]'}`
    },
  },
  methods: {
    onSubmit() {
      window.print();
    },
  },
};
</script>
<style >

@font-face {
  font-family: "Lato";
  src: local('Lato'), url("../public/fonts/Lato-Regular.woff") format("woff"), url("../public/fonts/Lato-Regular.woff2") format("woff2");
}
body{
  font-family: 'Lato', 'sans-serif' !important;
}
h1 {
  font-size: 2rem !important;
}
.secondary-item {
  padding-left: 3rem !important;
}
.btn-wtf {
  background-color: #ef7c21 !important;
}
.form-label {
  font-weight: bold;
}
.main-qr-code{
  height: 200px;
}
@media print {
  .container{
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .main-qr-code{
    height: 350px !important;
  }
  h1 {
    font-size: 1.2rem !important;
  }
  h2{
    font-size: 1.1rem !important;

  }
  h3{
    font-size: 1.1rem !important;
  }
  .mt-3{
    margin-top: 0 !important;
  }
  input{
    font-size: 0.5rem;
  }
  .beantraungs-text{
    font-size: 0.8rem !important;
  }
  .zulassung{
    font-size: 1.0rem !important;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
  .job_chaos {
    display: flex;
    flex-wrap: wrap;
  }
  .chaos_connection,
  .job_situation {
    flex: 1 0 0;
    padding: 15px;
  }

}
</style>
